//
// Handles an axios error, attempting to provide some useful information somewhere.
//

import { useAlertStore } from '../stores/alert-store'

export function handleAxiosError(error) {
    // Put this here and not at top as otherwise pinia is not active when service loaded.
    const alertStore = useAlertStore();
    
    let errorMessage = '';
    if (error.response) {
        let msg = "";
        if (error.response.statusText) {
            msg = error.response.statusText;
        }
        if (error.response.data && error.response.data.error) {
            msg = `${msg}, ${error.response.data.error}`;
        }
        errorMessage = `Error from server: ${msg}, Status: ${error.response.status}`;
    } else if (error.request) {
        errorMessage = `Request failed: ${error.request.statusText} Status: ${error.request.status}`;
    } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = `Error: ${error.message}`;
    }
    console.error(errorMessage);
    alertStore.setAlert(errorMessage, 'error');
}
