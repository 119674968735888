<template>
    <div class="text-center">
        <v-snackbar v-model="showAlert" :color="snackColor()">
            {{alertStore.alert}}
            <template v-slot:actions>
                <v-btn color="white"
                       variant="text"
                       @click="showAlert = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useAlertStore } from "../stores/alert-store";
import { storeToRefs } from "pinia";

const alertStore = useAlertStore();
const { alert, alertType } = storeToRefs(alertStore);

const showAlert = ref(false );

function snackColor() {
    return alertType.value === 'error' ? 'black' : 'gray darken-3';
}

watch(alert, () => {
    if (alert.value === '') {
        showAlert.value = false;
    } else {
        showAlert.value = true;
    }
})

// Once the alert has closed, clear the alert value. This is so that if we get repeated alerts with the
// same text, they will all be shown. If the alert text used is the same as the previous one, the 
// watch(alert, ...) doesn't fire.
watch(showAlert, () => {
    if (!showAlert.value) {
        alertStore.clearAlert();
    }
})

</script>
