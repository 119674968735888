<template>
    <v-container fluid>
        <div class="text-h4 my-3">RedRatHub Log</div>
        <v-data-table density="compact"
                      :headers="headers"
                      :items="logStore.logMessages"
                      :items-per-page="-1"
                      class="elevation-1 log-table">
            <template #headers="{columns}" class="table-header">
                <tr>
                    <template v-for="column in columns" :key="column.key">
                        <td class="td-border">
                            <span class="mr-2 ">{{ column.title }}</span>
                        </td>
                    </template>
                </tr>
            </template>
            <template #bottom>
            </template>
        </v-data-table>
    </v-container>
</template>

<script setup>
  import {useLogStore} from "../stores/log-store";
  
  const headers = [
    {title: 'Number', key: 'id'},
    {title: 'Timestamp', key: 'timestamp', width: "20%"},
    {title: 'Level', key: 'level'},
    {title: 'Message', key: 'message'},
  ]
  
  const logStore = useLogStore();
  
  function logLevelClass(item) {
    return `log${item.level}`;
  }
</script>
