<template>
    <v-app-bar dark color="red-darken-4">
        <template v-slot:prepend>
            <v-icon size="37" class="ml-2">mdi-remote</v-icon>
        </template>
        <v-app-bar-title class="text-h4 text-uppercase">
            Redrat Hub
        </v-app-bar-title>

        <v-spacer></v-spacer>

        <v-toolbar-items>
            <v-btn variant=text to="/">Devices</v-btn>
            <v-btn variant=text to="/settings">Settings</v-btn>
            <v-btn variant=text to="/log">log</v-btn>
            <v-btn variant=text to="/docs">docs</v-btn>
            <v-btn variant=text href="/swagger" target="_apitab">api</v-btn>
        </v-toolbar-items>
    </v-app-bar>
</template>

<script setup>

</script>
