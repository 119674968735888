import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from "axios";
import {handleAxiosError} from "../services/axios-service";

export const useConfigStore = defineStore( 'config', () => {
    const appVersion = ref('');

    async function loadAppVersion() {
        try {
            const response = await axios.get(`/api/config/version`);
            appVersion.value = response.data.toFixed(2);
        } catch (error) {
            handleAxiosError(error);
        }
    }

    return { appVersion, loadAppVersion };
});
