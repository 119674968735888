import {HubConnectionBuilder, LogLevel} from "@microsoft/signalr";
import {useLogStore} from "../stores/log-store";

export default function () {

    const logStore = useLogStore();

    let connection = '';

    async function createConnection() {
        connection = new HubConnectionBuilder()
            .withUrl("/ws")
            .configureLogging(LogLevel.Information)
            .build();

        connection.onclose(async () => {
            await openSignalR();
        });

        connection.on('LogMessage', (logMessage) => {
            logStore.addLogMessage(logMessage);
        });

        connection.on('GbV40DataUpdate', (gbV40Data) => {
            dataStore.addGbDataUpdate(gbV40Data);
        });

        await openSignalR();
    }

    async function openSignalR() {
        try {
            await connection.start();
            console.log('Websocket opened...');
        } catch (err) {
            console.error(err);
            setTimeout(startSignalR, 30000);
        }
    }

    function closeSignalR() {
        connection.close();
    }

    return { createConnection, closeSignalR }
}
