import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useAlertStore = defineStore( 'alert', () => {
    const alert = ref('');
    const alertType = ref('info');  // Can be either 'info' or 'error'

    function setAlert(newAlert, newAlertType) {
        alert.value = '';
        if (newAlertType.toLowerCase() === 'error') {
            alertType.value = 'error';
        } else {
            alertType.value = 'info'
        }
        alert.value = newAlert;
    }
    
    function clearAlert() {
        alert.value = '';
    }
    
    return { alert, alertType, setAlert, clearAlert };
});
