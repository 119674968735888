<template>
    <v-app>
      <alertBox class="mx-12"></alertBox>
      <toolbar />
      <v-main>
        <RouterView></RouterView>
      </v-main>
      <customFooter />
    </v-app>
</template>

<script setup>
  import {onMounted, onUnmounted} from "vue";
  import {useConfigStore} from "./stores/config-store";
  import useWebsocketService from "./services/websocket-service";

  const configStore = useConfigStore();
  const webSocket = useWebsocketService();

  onMounted( async () => {
    await configStore.loadAppVersion();
    await webSocket.createConnection();
  });

  onUnmounted( () => {
    webSocket.closeSignalR();
  });
</script>
