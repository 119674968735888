<template>
    <v-dialog persistent max-width="460">
        <v-card>
            <v-toolbar dark color="red-darken-3">
                <v-card-title>
                    <v-icon size="25" class="mt-n1">mdi-plus-network</v-icon>
                    Edit Network Properties
                </v-card-title>
            </v-toolbar>
            <v-card-text>

                <div class="text-h6 mx-3 mt-5">
                    <strong>RedRat:</strong> {{ redrat.name }}
                </div>
                <v-switch class="ma-4" inset color="red-darken-3" v-model="formData.dhcp" :label="formData.dhcp ? 'Dynamic' : 'Static'"></v-switch>
                <v-text-field class="mx-3"
                              align-centre
                              outlined
                              label="IP Address"
                              v-model="formData.formIpAddress"
                              :error-messages="checkErrors('formIpAddress')"
                              :disabled="formData.dhcp"
                              @input="v$.formIpAddress.$touch()"
                              @blur="v$.formIpAddress.$touch()">
                </v-text-field>
                <v-text-field class="mx-3"
                              align-centre
                              outlined
                              label="Subnet Mask"
                              v-model="formData.formNetmask"
                              :error-messages="checkErrors('formNetmask')"
                              :disabled="formData.dhcp"
                              @input="v$.formNetmask.$touch()"
                              @blur="v$.formNetmask.$touch()">
                </v-text-field>
                <v-text-field class="mx-3"
                              align-centre
                              outlined
                              label="Gateway"
                              v-model="formData.formGateway"
                              :error-messages="checkErrors('formGateway')"
                              :disabled="formData.dhcp"
                              @input="v$.formGateway.$touch()"
                              @blur="v$.formGateway.$touch()">
                </v-text-field>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red-darken-2"
                       variant="text"
                       @click="close()">
                    Cancel
                </v-btn>
                <v-btn color="red-darken-2"
                       variant="text"
                       @click="apply()">
                    Apply
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
    import { ipAddress, required } from '@vuelidate/validators'
    import { useVuelidate } from '@vuelidate/core'
    import { reactive, onMounted, watch } from 'vue'
    import { getNetworkInfo } from '../services/redrat-service'
    
    const props = defineProps(['show', 'redrat'])
    const emit = defineEmits(['close', 'update-network'])

    const formData = reactive({
        formIpAddress: '',
        formNetmask: '',
        formGateway: '',
        dhcp: false,
    })

    const validations = {
        formIpAddress: { ipAddress },
        formNetmask: { ipAddress },
        formGateway: { ipAddress },
        dhcp: {}
    }
    
    const v$ = useVuelidate(validations, formData)

    watch(() => props.show, () => {
        if (props.show) {
          // When dialog re-shown update state
          formData.formIpAddress = formData.formNetmask = formData.formGateway = "000.000.000.000";
          getNetworkInfo(props.redrat).then(nwk => {
            formData.dhcp = nwk.dhcp;
            formData.formIpAddress = nwk.ipAddress;
            formData.formNetmask = nwk.netmask;
            formData.formGateway = nwk.gateway;
          });
        }
    })

    function checkErrors(fieldValue) {
        return checkIpAddressFormat(v$.value[fieldValue]);
    }

    function checkIpAddressFormat(textBoxIpAddress) {
        const errors = [];
        if (!textBoxIpAddress.$error) return errors;
        errors.push('Invalid address.');
        return errors;
    }

    function close() {
        // Clear errors for next time dialog used.
        //v$.value.$reset()
        emit('close');
    }

    function apply() {
        // Clear errors for next time dialog used.
        v$.value.$reset();
        emit('update-network', props.redrat, formData.dhcp, formData.formIpAddress, formData.formNetmask, formData.formGateway);
    }
</script>