<template>

    <NavigationDrawer :menu-data="menuData"/>

    <v-container>

        <p class="text-h4 my-3"><a id="top"></a>Using RedRat Hub</p>

        <p class="text-body-2 mb-3">
            RedRat Hub is an application to act as a bridge between RedRat devices and third party applications.
            It supports two mechanisms for control of RedRat devices by client applications; using an HTTP REST API and a simple
            socket communication protocol. Details and links to example code/calls are given below, so please read on...
        </p>
        <p class="text-body-2 mb-3">
            RedRat Hub runs on Windows and Linux using .NET 6.0.
        </p>

        <v-divider class="my-4"></v-divider>

        <p class="text-h5 my-3"><a id="settings"></a>Settings and Configuration</p>
        <p class="text-body-2 mb-3">
            RedRatHub's behaviour can be configured via command line options or a settings file. The command line options generally take precedence over the settings file.
        </p>
        <p class="text-body-2 mb-3">
            The <a href="/settings">settings</a> page gives a description of each configuration value, plus the current and default values.
        </p>

        <p class="text-h6 my-3">Command Line Options</p>
        <pre>
            RedRatHub:
              Multiplexes commands for control of RedRat devices.

            Usage:
              RedRatHub [options]

            Options:
              --config &lt;config&gt;            File containing a list of RedRat Hub commands to execute on startup.
              --irdata &lt;irdata&gt;            A list of files containing IR signal data.
              --noscan                     Prevents the hub from scanning for RedRat devices on startup.
              --conntimeout &lt;conntimeout&gt;  Timeout period for the connection to a RedRat device will be dropped if no activity.
                                           Units of seconds. Set to 0 to disable.
              --nohttp                     Prevents the HTTP server from starting (Web UI and HTTP REST API).
              --httpport &lt;httpport&gt;        The port used by the HTTP server.
              --port &lt;port&gt;                Port on which the socket server listens for client connections.
              --conns &lt;conns&gt;              The number of sockets to make available for simultaneous client connections.
              --eval                       Use dummy RedRat devices rather than actual devices for testing and evaluation.
              --version                    Show version information
              -?, -h, --help               Show help and usage information
        </pre>

        <p class="text-h6 my-3">Configuration Settings</p>
        <p class="text-body-2 mb-3">
            The file <code>appsettings.json</code> contains various configuration settings. These are loaded
            when RedRat Hub starts, so any changes made will not be picked up until it is restarted.
        </p>

        <p class="text-h6 my-3">Connections to RedRat Devices</p>
        <p class="text-body-2 mb-3">
            RedRat hub makes a connection to an irNetBox or RedRat-X when it is needed, typically once the first signal transmission
            command has been received. Once a connection has been established it is held open until it times-out or a disconnection
            command is received.
        </p>
        <p class="text-body-2 mb-3">
            The USB RedRat3-II does not have explicit connection management, so appears as always connected.
        </p>

        <p class="text-h6 my-3">Allowed and Blocked Devices</p>
        <ul class="text-body-2 mb-3 ml-10">
            <li><b>Allowed:</b> RedRatHub can connect to and use RedRat devices in the allowed list.</li>
            <li><b>Blocked:</b> These cannot be used by this RedRatHub instance.</li>
        </ul>

        <p class="text-body-2 mb-3">
            RedRat Hub can connect to any RedRat device that it discovers, however this could be a problem if multiple
            RedRatHub instances are running on a network, or other applications are using some RedRats.
            So RedRat devices can be blocked which will prevent the hub from connecting to them.
        </p>
        <p class="text-body-2 mb-3">
            By default, all RedRat devices discovered and placed on the allowed list. API commands can then be used
            to block or re-allow specific devices.
        </p>
        <p class="text-body-2 mb-3">
            <i>Hub query</i> commands can be added to the config file so that the same device setup is used each time RedRatHub is started.
        </p>

        <p class="text-h6 my-3">IR Data</p>
        <p class="text-body-2 mb-3">
            On startup, RedRatHub can load one or more IR dataset files as given with the <code>--irdata</code> option. This saves client
            applications from having to handle IR datasets, so simplifying development. The loaded IR data can be viewed on the
            <a href="/settings">settings</a> page.
        </p>
        <p class="text-body-2 mb-3">
            If an application handles IR data itself, then signals can also be passed to RedRatHub via the API call
            to transmit IR data.
        </p>

        <v-divider class="my-4"></v-divider>

        <p class="text-h5 my-3"><a id="restapi"></a>HTTP Rest API</p>
        <p class="text-body-2 mb-3">
            This API was added with version 5.00 of RedRatHub.
            Swagger based documentation and examples are given on the <a href="/swagger">REST API</a> page.
        </p>

        <v-divider class="my-4"></v-divider>

        <p class="text-h5 my-3"><a id="socketserver"></a>Socket Server Commands</p>

        <p class="text-h6 my-3">Communication Protocol</p>

        <p class="text-body-2 mb-3">
            The socket server uses a TCP socket interface to accept commands on port 40000 by default.
            Commands are sent to the hub in plain text as UTF-8 strings. The general format is a list of one or more key="value" statements separated
            by spaces and terminated with a line feed (n) character. Statements may be in any order and are not case sensitive.
            Multiple commands may be sent through a single connection or through multiple connections from different clients.
        </p>

        <p class="text-body-2 mb-3">
            The hub responds to each command it receives and clients should always wait for this response to avoid sending commands
            faster than the hub can process them. Short responses are simply text terminated with line feed, for example 'OK'.
            Responses that span multiple lines are enclosed by braces. For example:
        </p>
        <pre>
            {
            line 1
            line 2
            }
        </pre>

        <p class="text-h6 my-3">Command Overview</p>
        <p class="text-body-2 mb-1">
            There are three type of command:
        </p>
        <ul class="text-body-2 mb-3 ml-10">
            <li><b>Hub Queries:</b> These allow getting and setting of the hub’s state and configuration.</li>
            <li><b>Hardware Queries:</b> Discovery of RedRat devices and return information about them.</li>
            <li><b>IR Signal Output:</b> Instructions for transmission of IR signals via RedRat devices.</li>
        </ul>

        <p class="text-h6 my-3">Hub Queries</p>

        <p class="text-body-2 mb-3">
            These have the basic form <code>hubquery="[command]" [additional parameters]</code>.
        </p>

        <v-card class="mb-5">
            <v-card-text>
                <v-container>
                    <v-row v-for="(item, index) in hubQueryItems" :key="item.command">
                        <v-col>
                            <p class="text-overline text-red-lighten-2">Command</p>
                            <code class="no-style">{{item.command}}</code>
                            <p class="text-caption mt-1">{{item.description}}</p>
                        </v-col>
                        <v-col>
                            <p class="text-overline text-red-lighten-2">Example</p>
                            <code class="font-weight-bold">{{item.example}}</code>
                        </v-col>
                        <v-col>
                            <p class="text-overline text-red-lighten-2">Response</p>
                            <p class="text-caption">{{item.response}}</p>
                        </v-col>
                        <v-divider v-if="index != hubQueryItems.length - 1"></v-divider>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <p class="text-h6 my-3">Hardware Queries</p>

        <p class="text-body-2 mb-3">
            The hub can retrieve information about RedRat devices.
            The general format is: <code>hardwareQuery="query text" [identifier]</code> where the query text may be one of the following:
        </p>

        <v-card class="mb-5">
            <v-card-text>
                <v-container>
                    <v-row v-for="(item, index) in hardwareQueryItems" :key="item.command">
                        <v-col>
                            <p class="text-overline text-red-lighten-2">Command</p>
                            <code class="no-style">{{item.command}}</code>
                            <p class="text-caption mt-1">{{item.description}}</p>
                        </v-col>
                        <v-col>
                            <p class="text-overline text-red-lighten-2">Example</p>
                            <code class="font-weight-bold">{{item.example}}</code>
                        </v-col>
                        <v-col>
                            <p class="text-overline text-red-lighten-2">Response</p>
                            <p class="text-caption">{{item.response}}</p>
                        </v-col>
                        <v-divider v-if="index != hardwareQueryItems.length - 1"></v-divider>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

    <p class="text-h6 my-3">Signal Transmission</p>

    <p class="text-body-2 mb-3">
        Signal transmission commands are hardware agnostic and return 'OK' as soon as transmission has been completed.
        A signal transmission command has the form: <code>[identifier] dataset="dataset name" signal="signal name" output="[output descriptor]"</code>.
    </p>

    <v-card class="mb-5">
        <v-card-text>
            <v-container>
                <v-row v-for="(item, index) in signalTransmissionItems" :key="item.command">
                    <v-col>
                        <p class="text-overline text-red-lighten-2">Command</p>
                        <code class="no-style">{{item.command}}</code>
                        <p class="text-caption mt-1">{{item.description}}</p>
                    </v-col>
                    <v-col>
                        <p class="text-overline text-red-lighten-2">Example</p>
                        <code class="font-weight-bold">{{item.example}}</code>
                    </v-col>
                    <v-col>
                        <p class="text-overline text-red-lighten-2">Response</p>
                        <p class="text-caption">{{item.response}}</p>
                    </v-col>
                    <v-divider v-if="index != signalTransmissionItems.length - 1"></v-divider>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>

    <p class="text-h6 my-3"><a name="rrid"></a>Definitions Used in Commands</p>
    <p class="text-body-2 mb-3">
        Many of the queries and commands listed above use definitions for RedRat devices, datasets, files etc.
    </p>

    <v-card class="mb-5">
        <v-card-text>
            <v-container>
                <v-row v-for="(item, index) in commandDefs" :key="item.definition">
                    <v-col>
                        <p class="text-overline text-red-lighten-2">Definition</p>
                        <code class="no-style">{{item.definition}}</code>
                        <p class="text-caption mt-1">{{item.description}}</p>
                    </v-col>
                    <v-col>
                        <p class="text-overline text-red-lighten-2">Examples</p>
                        <p v-for="example in item.examples"
                           :key="item.example"
                           class="mb-1">
                            <code class="font-weight-bold">{{example}}</code>
                        </p>
                    </v-col>
                    <v-divider v-if="index != commandDefs.length - 1"></v-divider>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>

    <v-divider class="my-4"></v-divider>

    <p class="text-h5 my-3"><a id="logging"></a>Logging</p>

    <p class="text-body-2 mb-3">
        Log output can be sent to a number of destinations: The console, log files and a web page.
        If RedRat Hub is running as a Windows Service, then log output can also be configured to be forwarded to
        Windows Event Viewer, in a separate <i>RedRat</i> application log.
    </p>

    <p class="text-body-2 mb-3">
        The file <code>NLog.config</code> contains settings for adjusting which log destinations are enabled, the log levels
        and how log messages are displayed. Any changes made while RedRat Hub is running will be picked up immediately.
        See the <a href="/settings">settings</a> page for the current log settings.
    </p>

    <p class="text-body-2 mb-3">
        For each log target, the verbosity of the logging can be set. The levels are:
    </p>
    <ul class="text-body-2 mb-3 ml-10">
        <li><b>Fatal:</b> Something has gone seriously wrong! RedRat Hub is probably not usable.</li>
        <li><b>Error:</b> A significant issue occurred which means that RedRat Hub is probably not working correctly.</li>
        <li><b>Warn:</b> An error was encountered, but operation can probably continue.</li>
        <li><b>Info:</b> Reports on application operation.</li>
        <li><b>Debug:</b> More detailed information about application operation.</li>
        <li><b>Trace:</b> The most verbose type of log output is generated.</li>
    </ul>

            <!-- UP TO HERE -->

    <v-divider class="my-4"></v-divider>

    <p class="text-h5"><a id="changehistory"></a>Change History</p>
    <v-list>
        <v-list-item v-for="item in changeHistory.slice().reverse()"
                     :key="item.version"
                     class="mb-3">
            <v-list-item-title class="text-body-1 font-weight-medium">Version {{ item.version }} - {{ item.date }}</v-list-item-title>
            <ul class="text-body-2 ml-10">
                <li v-for="change in item.changes"
                    class="my-1">
                    {{ change }}
                </li>
            </ul>
        </v-list-item>
    </v-list>

    </v-container>
</template>

<script setup>

    const menuData = {
        title: 'RedRatHub Docs',
        page: '/docs#top',
        items: [
            {
                title: 'Settings and Config',
                icon: 'mdi-cogs',
                link: '/docs#settings'
            }, {
                title: 'HTTP Rest API',
                icon: 'mdi-swap-horizontal-bold',
                link: '/docs#restapi'
            }, {
                title: 'Socket Server',
                icon: 'mdi-access-point',
                link: '/docs#socketserver'
            }, {
                title: 'Logging',
                icon: 'mdi-clipboard-text',
                link: '/docs#logging'
            }, {
                title: 'Change History',
                icon: 'mdi-help-box',
                link: '/docs#changehistory'
            }
        ]
    }

    const hubQueryItems = [
        {
            command: 'hub version',
            description: 'Returns the software version of the hub.',
            example: 'hubquery="hub version"',
            response: 'E.g. "RedRatHub (5.10)"'
        }, {
            command: 'list redrats',
            description: 'Returns the list of RedRat devices as of the last scan.',
            example: 'hubquery="list redrats"',
            response: 'Multiline list of RedRat device info.',
        }, {
            command: 'find redrats',
            description: 'Performs a scan for RedRat devices and returns the result. Existing connections are preserved.',
            example: 'hubquery="find redrats"',
            response: 'Multiline list of known and discovered RedRat device info.',
        }, {
            command: 'forget redrats',
            description: 'Disconnect from any connected devices and clear list of known devices. Use "find redrats" to re-discover available devices.',
            example: 'hubquery="forget redrats"',
            response: '"OK"',
        }, {
            command: 'allow all redrats',
            description: 'Allow the hub to connect to any RedRat it has discovered. This is the default behaviour.',
            example: 'hubquery="allow all redrats"',
            response: 'Multiline list of RedRat device info.',
        }, {
            command: 'block all redrats',
            description: 'The hub disconnects from all RedRats and prevents the use of any RedRat devices in the blocked list.',
            example: 'hubquery="block all redrats"',
            response: 'Multiline list of RedRats indicating which are blocked.',
        }, {
            command: 'allow redrat [device def]',
            description: 'Allows the hub to connect to the RedRat device specified by [identifier].',
            example: 'hubquery="allow redrat" ip="192.168.1.50"',
            response: 'Multiline list of RedRats indicating status.',
        }, {
            command: 'block redrat [device def]',
            description: 'Prevents the hub from connecting to the RedRat device specified by [identifier]. If there is an existing connection it is closed',
            example: 'hubquery="block redrat" ip="192.168.1.34"',
            response: 'Multiline list of RedRats indicating status.',
        }, {
            command: 'list datasets',
            description: 'Get the list of IR signal datasets loaded from the signal files.',
            example: 'hubquery="list datasets"',
            response: 'Multiline list of dataset names.',
        }, {
            command: 'list signals in dataset [dataset def]',
            description: 'Gets the list of signal names for the given dataset.',
            example: 'hubquery="list signals in dataset" dataset="AMP"',
            response: 'Multiline list of signal names',
        }, {
            command: 'add ir-file [file def]',
            description: 'Adds a new IR dataset file (XML or TXT) to the IR data used by the application. If there are datasets with the same name as existing datasets, then old data will be overwritten.',
            example: 'hubquery="add ir-file" file="DeviceDB2.xml"',
            response: '"OK" or error message.',
        }, {
            command: 'load ir-file [file def]',
            description: 'Loads a new IR dataset file, replacing (and deleting) any IR dataset information known by the application.',
            example: 'hubquery="load ir-file" file="DeviceDB2.xml"',
            response: '"OK" or error message.',
        }, {
            command: 'add redrat [device def]',
            description: 'Adds a RedRat to the list of devices known to the hub.',
            example: 'hubquery="add redrat" name="My irNetBox-IV"',
            response: 'Multiline list of RedRats or an error message.',
        }, {
            command: 'remove redrat [device def]',
            description: 'Removes a RedRat from the list of devices known to the hub.',
            example: 'hubquery="remove redrat" ip="192.168.1.53"',
            response: 'Multiline list of RedRats or an error message.',
        }, {
            command: 'connect redrat [device def]',
            description: 'Forces RedRat Hub to connect to the given RedRat. It usually only connects on demand.',
            example: 'hubquery="connect redrat" mac="70-B3-D5-FD-10-02"',
            response: '"OK" or an error message.',
        }, {
            command: 'disconnect redrat [device def]',
            description: 'Closes the connection to the given RedRat. The connection will be re-established on demand, or can be forced with the connect redrat command.',
            example: 'hubquery="connect redrat" ip="192.168.1.52"',
            response: '"OK" or an error message, e.g. if the RedRat has been blocked.',
        }, {
            command: 'save config [file def]',
            description: 'Write the current state of the hub to a file, such as blocked or allowed devices.',
            example: 'hubquery="save config" file="test-config.txt"',
            response: '"OK" or an error message.',
        }, {
            command: 'load config [file def]',
            description: 'Configure the hub by running commands in a file.',
            example: 'hubquery="load config" file="test-config.txt"',
            response: '"OK" or an error message.',
        }
    ]

    const hardwareQueryItems = [
        {
            command: 'firmware version [device def]',
            description: 'Gets the version of the firmware running on the given RedRat device.',
            example: 'hardwarequery="firmware version" ip="192.168.1.50"',
            response: 'The firmware version.',
        }, {
            command: 'serial number [device def]',
            description: 'Gets the serial number the given RedRat device.',
            example: 'hardwarequery="serial number" ip="192.168.1.50"',
            response: 'The serial number, e.g. 20001',
        }, {
            command: 'hardware type [device def]',
            description: 'Gets the hardware type of the given RedRat device.',
            example: 'hardwarequery="hardware type" ip="192.168.1.52"',
            response: 'The hardware type, e.g. RedRat4_III_IP, RedRat4_IV_IP or RedRatX_IP etc.',
        }, {
            command: 'reset [device def]',
            description: 'Resets the RedRat device. This is not supported for the irNetBox-I & II and does not reset the XPort module on an irNetBox-III.',
            example: 'hardwarequery="reset" ip="192.168.1.52"',
            response: '"OK" or an error message.'
        }, {
            command: 'reset nic [device def]',
            description: 'Resets the network interface for an irNetBox. This is useful if a connection to the box can\'t be made, \
            for example if a previous application left the connection hanging.',
            example: 'hardwarequery="reset nic" ip="192.168.1.52"',
            response: '"OK" or an error message. It can take a number of seconds to return.'
        },
    ]

    const signalTransmissionItems = [
        {
            command: '[dataset def] [signal def] [device def] [output def]',
            description: 'Transmits the given IR signal via the selected outputs on the redrat device. \
                        The order of the command sections is not important.',
            example: 'dataset="sky+" signal="9" ip="192.168.1.52" output="1"',
            response: '"OK" or an error message.'
        }
    ]

    const commandDefs = [
        {
            definition: '[device def]',
            description: 'Identifies a RedRat device using its IP address, MAC address or name.',
            examples: [
                'ip="192.168.1.34"',
                'mac="70-B3-D5-FD-10-02"',
                'name="irNetBox Rack-4"',
            ]
        }, {
            definition: '[file def]',
            description: 'Defines the name of a file for loading or saving data.',
            examples: [
                'file="config.txt"',
                'file="DeviceDB.xml"',
            ]
        }, {
            definition: '[dataset def] [signal def]',
            description: 'These definitions are always used to together to define an single IR signal to be used in the command.  \
                        The values are not case sensitive.',
            examples: [
                'dataset="sky+" signal="play"',
                'dataset="STB1" signal="1"',
            ]
        }, {
            definition: '[output def]',
            description: 'Defines one or more IR outputs to be used with this IR signal transmission. It can also include an optional \
                        power output value, e.g. 16:80 is output number 16 at 80% power.',
            examples: [
                'output="3"',
                'output="3,5,7"',
                'output="1-8"',
                'output="16:80"',
            ]
        }
    ]

    const changeHistory = [
        {
            version: '3.01',
            date: 'Sept 2012',
            changes: [
                'First version released.'
            ]
        }, {
            version: '4.09',
            date: '18 Sept 2013',
            changes: [
                'Updated to use.NET 4.5.',
                'Added the support for Linux / text IR dataset files.',
                'Added –noscan on startup – it will then not scan for irNetBoxes.',
                'Added loading and saving of configuration files.',
                'Added runtime loading of IR signal datasets.',
                'Considerable improvements in performance and robustness.',
                'Some changes to the commands for consistency.',
                'Supports irNetBoxes with the new XPort - 05 version.Further information on this is available here.',
                'There may be some irNetBox connection issues when running under Mono.',
            ]
        }, {
            version: '4.10',
            date: '2 Dec 2013',
            changes: [
                'Repeat and duration options added for long IR signals.',
                'Toggle and double IR signals are better supported, especially for async output via the irNetBox-III.',
            ]
        }, {
            version: '4.11',
            date: '20 Jan 2014',
            changes: [
                'Fixed issue with TCP connection locking that caused problems running under Mono.',
            ]
        }, {
            version: '4.13',
            date: '29 Oct 2015',
            changes: [
                'Added hardwareQuery commands – connect, disconnect, reset and resetXPort.',
                'Updates to core RedRat comms code.',
                'Some improvements to debug output.',
            ]
        }, {
            version: '4.14',
            date: '27 June 2016',
            changes: [
                'Added support for continuous IR output.',
                'Uses V4.11  of RedRat core code.',
            ]
        }, {
            version: '4.17',
            date: '21 Oct 2016',
            changes: [
                'Supports the irNetBox-IV.',
                'Improved error reporting.',
            ]
        }, {
            version: '4.18',
            date: '14 Jun 2017',
            changes: [
                'Bug fix: Various hubquery commands didn’t work when using the irNetBox name rather than IP address.',
            ]
        }, {
            version: '4.19',
            date: '15 Jun 2017',
            changes: [
                'Improved speed when using multiple outputs on a MK-II irNetBox.',
            ]
        }, {
            version: '4.20',
            date: '28 July 2017',
            changes: [
                'Supports the RedRat-X.',
            ]
        }, {
            version: '4.21',
            date: '6 Sept 2017',
            changes: [
                'Added command to reset the network interface card for an irNetBox – "reset nic".',
                'Added timestamps to console output.',
            ]
        }, {
            version: '4.22',
            date: '12 Sept 2017',
            changes: [
                'Bug fix: Cached RedRat now cleared whenever disconnected, not just through disconnect command.',
                'Front blaster is used if no output is specified for RedRat-X.',
            ]
        }, {
            version: '4.23',
            date: '22 Sept 2017',
            changes: [
                'Connections are now closed to RedRats that are removed using the "remove redrat" command.',
            ]
        }, {
            version: '4.24',
            date: '1 Nov 2017',
            changes: [
                'Improved MAC support for UDP broadcasts and XMOS USB device discovery.',
                'Improved command format validation.',
            ]
        }, {
            version: '4.25',
            date: '3 Apr 2018',
            changes: [
                'Fixed startup race condition causing application to hang on Linux.',
                'Command line arguments no longer case sensitive.',
                'Console log output visibility improvements.',
                'Uses .NET 4.5.2 (Windows).',
            ]
        }, {
            version: '4.27',
            date: '25 July 2018',
            changes: [
                'RedRat-X support added for the command "reset nic".',
                'For backwards compatibility, some irNetBox specific commands were added back in (they had been replaced with generic redrat commands). These are:' +
                '"connect irnetbox", "disconnect irnetbox", "add irnetbox", "remove irnetbox", "whitelist irnetbox", "blacklist irnetbox", "list irnetboxes"',
            ]
        }, {
            version: '4.28',
            date: '25 Sept 2018',
            changes: [
                'Identifying a RedRat device by MAC address didn\'t work.Now fixed.',
            ]
        }, {
            version: '4.29',
            date: '10 Jan 2020',
            changes: [
                'Added the ability to transmit raw IR data using the "data" and "maxlength" parameters.',
            ]
        }, {
            version: '5.00',
            date: '5th Feb 2020',
            changes: [
                'Renamed to RedRatHub rather than RedRatHubCmd.',
                'Added HTTP server (NancyFx) for a simple web UI and REST API.',
                'An additional hub query "list redrats" has been added.',
            ]
        }, {
            version: '5.01',
            date: '19th Feb 2020',
            changes: [
                'Improved shutdown when running as service on Linux.',
            ]
        }, {
            version: '5.02',
            date: '2nd March 2020',
            changes: [
                'Added "reset" and "resetnic" commands via rest API.',
            ]
        }, {
            version: '5.10',
            date: '6th Aug 2020',
            changes: [
                'Major update to .NET Core 3.1, replacing NancyFx with ASP.NET Core.',
                'Overhall of web UI, including Swagegr for REST API docs.',
                'Added the "--eval" startup option.',
            ]
        }, {
            version: '5.11',
            date: '10th Sept 2020',
            changes: [
                'Web server was only available via "localhost" on some computers. Now fixed.',
            ]
        }, {
            version: '5.20',
            date: '5th Dec 2020',
            changes: [
                'Moved to .NET 5.0.',
                'Can edit RedRat device name and network configuration from the web UI.',
                'Replaced device whitelist/blacklist with allow/block.',
                'Fixed bug in RedRat device scanning. If a RedRat-X was moved from USB to IP (or visa versa) it appeared twice.',
                'RedRat device types given as product name (irNetBox IV) rather than code name (RedRat4_IV).',
                'Various other small fixes and improvements.'
            ]
        }, {
            version: '5.21',
            date: '13th Dec 2020',
            changes: [
                'Improved use of page width in web pages.',
                'Added information about IR output definition to docs.',
            ]
        }, {
            version: '5.22',
            date: '24th Feb 2021',
            changes: [
                'Correct version number displayed in UI if minor version < 10.',
                'Updates to various packages.',
                'Can run as a Windows Service',
                'Fixed null ref exception if no IR data DB loaded.'
            ]
        }, {
            version: '5.23',
            date: '25th Nov 2021.',
            changes: [
                'Improved startup logging to always show program info on console.',
                'Fix to device/context handles when using LibUsb (Linux & Mac).'
            ]
        }, {
            version: '6.00',
            date: '30th June 2022.',
            changes: [
                'Moved to .NET 6.0.',
                'Using simplified ASP.NET Core 6 web hosting model.',
                'If repeat count > 0 but there is no repeat data in signal, an error is returned.',
                'Fix to RedRat core code to support default libusb version (1.0.21) on Ubuntu 18.04.',
                'Log messages now pushed to client via SignalR.'
            ]
        }, {
            version: '6.01',
            date: '12th July 2022.',
            changes: [
                'An empty or no "dummyUnits" section in appsettings.json no longer causes an exception.',
            ]
        }, {
            version: '6.02',
            date: '31st Oct 2022.',
            changes: [
                'Fix to evaluation mode, which was broken following changes in V6.01.',
            ]
        }, {
            version: '6.03',
            date: '13th June 2023.',
            changes: [
                'Removed all global mutex code for Mac/Linux. Prevented older devices working on Mac/Linux.',
                'Fixed bug preventing IP RedRat-Xs being found.',
            ]
        }, {
            version: '6.04',
            date: '31st Oct 2023',
            changes: [
                'Added support for the RedRat Pico',
                'Updated to use Vue3.',
            ]
        }
    ]
</script>
