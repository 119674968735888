<template>
    <v-footer app
              dark
              color="red-darken-4"
              inset
              class="footer text-body-2"
              style="position:absolute">
        <span>RedRat Hub - V{{configStore.appVersion}}</span>
        <v-spacer></v-spacer>
        <a class="footer-support text-white" href="mailto:support@redrat.co.uk">support@redrat.co.uk</a>
    </v-footer>
</template>

<script setup>
  import { useConfigStore } from '../stores/config-store';
  const configStore = useConfigStore();
</script>
