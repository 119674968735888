<template>
    <v-container fluid>
        <div class="text-h4 my-3">RedRat Devices</div>

        <v-data-table :headers="deviceHeaders"
                      :items="redrats()"
                      :hide-default-footer="true"
                      item-key="text"
                      :item-class="tableRowBackground"
                      items-per-page="-1"
                      class="elevation-1 text-body-2">

            <template #headers="{columns}" class="table-header">
                <tr>
                    <template v-for="column in columns" :key="column.key">
                        <td class="td-border">
                            <span class="mr-2 ">{{ column.title }}</span>
                        </td>
                    </template>
                </tr>
            </template>
            <template #item.isAllowed="{ item }">
                <v-icon v-if="item.isAllowed">mdi-check</v-icon>
                <v-icon v-else>mdi-close</v-icon>
            </template>

            <template #item.actions="{ item }">
                <v-tooltip bottom :open-delay="tooltipDelay">
                    <template #activator="{ props }">
                        <v-icon small color="grey-darken-1" v-bind="props" @click="showViewDeviceDialog(item)">mdi-dots-horizontal-circle</v-icon>
                    </template>
                    <span>Device properties</span>
                </v-tooltip>
                <v-tooltip bottom :open-delay="tooltipDelay">
                    <template #activator="{ props }">
                        <v-icon small color="grey-darken-1" v-bind="props" @click="showEditDeviceDialog(item)">mdi-pencil</v-icon>
                    </template>
                    <span>Edit device</span>
                </v-tooltip>
                <v-tooltip bottom v-if="item.isAllowed && item.hasNetworkInterface" :open-delay="tooltipDelay">
                    <template #activator="{ props }">
                        <v-icon small color="grey-darken-1" v-bind="props" @click="showEditNetworkDialog(item)">mdi-plus-network</v-icon>
                    </template>
                    <span>Network config</span>
                </v-tooltip>
                <v-tooltip bottom :open-delay="tooltipDelay">
                    <template #activator="{ props }">
                        <v-icon small color="grey-darken-1" v-bind="props" @click="showRemoveDeviceDialog(item)">mdi-delete</v-icon>
                    </template>
                    <span>Remove device</span>
                </v-tooltip>
            </template>

            <template #bottom>
            </template>

        </v-data-table>

        <v-dialog persistent v-model="viewDeviceDialog" max-width="575">
            <v-card>
                <v-toolbar dark color="red-darken-3">
                    <v-card-title>
                        <v-icon size="25" class="mt-n1">mdi-dots-horizontal-circle</v-icon>
                        RedRat Device Properties
                    </v-card-title>
                </v-toolbar>

                <v-table class="devices-table text-body-2 font-weight-bold"
                         density="comfortable">
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td class="text-right font-weight-medium text-blue-grey-darken-1">{{ selectedRedRat.name }}</td>
                        </tr>
                        <tr>
                            <td>Device Type</td>
                            <td class="text-right font-weight-medium text-blue-grey-darken-1">{{ selectedRedRat.rrType }}</td>
                        </tr>
                        <tr v-if="selectedRedRat.isAllowed">
                            <td>Serial Number</td>
                            <td class="text-right font-weight-medium text-blue-grey-darken-1">{{ serialNumber }}</td>
                        </tr>
                        <tr v-if="selectedRedRat.isAllowed">
                            <td>Firmware Version</td>
                            <td class="text-right font-weight-medium text-blue-grey-darken-1">{{ firmware }}</td>
                        </tr>
                    </tbody>
                </v-table>

                <v-divider></v-divider>

                <v-table v-if="selectedRedRat.hasNetworkInterface && selectedRedRat.isAllowed"
                         class="devices-table text-body-2 font-weight-bold"
                         density="comfortable">
                    <tbody>
                        <tr>
                            <td>Connection</td>
                            <td class="text-right font-weight-medium text-blue-grey-darken-1">{{ selectedRedRat.connection === 'USB' ? 'USB' : 'IP' }}</td>
                        </tr>
                        <tr>
                            <td>IP Address</td>
                            <td class="text-right font-weight-medium text-blue-grey-darken-1">{{ networkData.ipAddress }}</td>
                        </tr>
                        <tr>
                            <td>Gateway Address</td>
                            <td class="text-right font-weight-medium text-blue-grey-darken-1">{{ networkData.gateway }}</td>
                        </tr>
                        <tr>
                            <td>Subnet Mask</td>
                            <td class="text-right font-weight-medium text-blue-grey-darken-1">{{ networkData.netmask }}</td>
                        </tr>
                        <tr>
                            <td>IP Assignment</td>
                            <td class="text-right font-weight-medium text-blue-grey-darken-1">{{ networkData.dhcp ? "DHCP" : "Static" }}</td>
                        </tr>
                        <tr>
                            <td>MAC Address</td>
                            <td class="text-right font-weight-medium text-blue-grey-darken-1">{{ networkData.macAddress }}</td>
                        </tr>
                    </tbody>
                </v-table>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn color="red-darken-2"
                           variant="text"
                           @click="viewDeviceDialog = false">
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog persistent v-model="editDeviceDialog" max-width="460">
            <v-card>
                <v-toolbar dark color="red-darken-3">
                    <v-card-title>
                        <v-icon size="25" class="mt-n1">mdi-pencil</v-icon>
                        Edit Device Properties
                    </v-card-title>
                </v-toolbar>
                <v-card-text class="mt-12">
                    <v-text-field class="mx-3"
                                  align-centre
                                  outlined
                                  v-model="editedRedRatName"
                                  :disabled="!selectedRedRat.isAllowed"
                                  label="Device name">
                    </v-text-field>
                    <v-row align="center" class="mx-12">
                        <v-switch inset color="red-darken-3" v-model="selectedRedRat.isAllowed" :label="selectedRedRat.isAllowed ? 'Allowed' : 'Blocked'"></v-switch>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom>
                            <template #activator="{ props }">
                                <v-icon left v-bind="props" >mdi-help-circle</v-icon>
                            </template>
                            <span>Is the RedRatHub instance allowed to use this RedRat?</span>
                        </v-tooltip>
                    </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red-darken-2"
                           variant="text"
                           @click="editDeviceDialog = false">
                        Cancel
                    </v-btn>
                    <v-btn color="red-darken-2"
                           variant="text"
                           @click="editDeviceDialogApply(selectedRedRat)">
                        Apply
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <networkProps v-model="editNetworkDialog" :show="editNetworkDialog" @close="editNetworkDialog = false" @update-network="updateNetwork" :redrat="selectedRedRat"></networkProps>

        <v-dialog persistent v-model="removeDeviceDialog" max-width="460">
            <v-card>
                <v-toolbar dark color="red-darken-3">
                    <v-card-title>
                        <v-icon size="25" class="mt-n1">mdi-delete</v-icon>
                        Remove Device
                    </v-card-title>
                </v-toolbar>
                <v-card-title class="headline">RedRat {{ selectedRedRat.name }}</v-card-title>
                <v-card-text>
                    <div>
                        Do you want to remove RedRat {{selectedRedRat.name}} from this RedRatHub instance?
                        A rescan will add it again.
                    </div>
                    <div class="mt-4">
                        To permanently prevent RedRatHub from using this device, add it to the <i>blocked</i> list.
                    </div>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red-darken-2"
                           variant="text"
                           @click="removeDeviceDialog = false">
                        Cancel
                    </v-btn>
                    <v-btn color="red-darken-2"
                           variant="text"
                           @click="removeRedRat()">
                        Remove
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog persistent v-model="restartWaitDialog" max-width="460">
            <v-card>
                <v-toolbar dark color="red-darken-3">
                    <v-card-title>
                        <v-icon left>mdi-backup-restore</v-icon>
                        <span>Waiting for restart...</span>
                    </v-card-title>
                </v-toolbar>
                <v-card-text>
                    <div class="text-center mt-4">
                        <v-progress-circular :size="40" :width="4"
                                             indeterminate
                                             color="red">
                        </v-progress-circular>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-btn small color="grey lighten-1" min-width="125" class="mt-6" v-on:click="rescan" :disabled="scanning">{{ scanButtonName() }}</v-btn>
    </v-container>
</template>

<script setup>
    import { ref, onMounted, onBeforeUnmount } from 'vue'
    import { useRedRatStore } from '../stores/redrat-store'
    import { getSerialNumber, getFirmware, getNetworkInfo, allowRedRat, blockRedRat, setName, setNetworkInfo } from '../services/redrat-service'

    const redratStore = useRedRatStore();

    const deviceTitle = "RedRat Devices"

    const deviceHeaders = [
        { title: 'Name', key: 'name' },
        { title: 'Type', key: 'rrType' },
        { title: 'Connection', key: 'connection' },
        { title: 'Allowed', key: 'isAllowed', sortable: false },
        { title: 'Connection State', key: 'connState' },
        { title: 'Timeout (s)', key: 'timeout' },
        { title: '', key: 'actions', sortable: false, align: 'left' },
    ]

    const tooltipDelay = 1000;
    const showError = ref(false)
    const networkData = ref({
                     dhcp: true,
                     ipAddress: '000.000.000.000',
                     netmask: '000.000.000.000',
                     gateway: '000.000.000.000',
                     macAddress: '',
                 })
    const tableRowBackground = ref('')
    const viewDeviceDialog = ref(false)
    const selectedRedRat = ref('')
    const editDeviceDialog = ref(false)
    const editedRedRatName = ref('')
    const scanning = ref(true)
    const editNetworkDialog = ref(false)
    const restartWaitDialog = ref(false)
    const serialNumber = ref('')
    const firmware = ref('')
    const removeDeviceDialog = ref(false)
    const loadTimer = ref('')

    onMounted(async () => {
        scanning.value = false
        await loadRedRats();
    })

    function redrats() {
        scanning.value = false;
        return redratStore.redrats;
    }

    async function loadRedRats() {
        if (!scanning.value) {
            await redratStore.loadRedRatData();
        }
        loadTimer.value = setTimeout(() => loadRedRats(), 5000);
    }

    function showViewDeviceDialog(redrat) {
        selectedRedRat.value = redrat;
        getSerialNumber(redrat).then(sn => serialNumber.value = sn);
        getFirmware(redrat).then(fw => firmware.value = fw);
        getNetworkInfo(redrat).then(nwk => networkData.value = nwk);
        viewDeviceDialog.value = true;
    }

    function showEditDeviceDialog(redrat) {
        selectedRedRat.value = redrat;
        editedRedRatName.value = redrat.name;
        editDeviceDialog.value = true;
    }

    async function editDeviceDialogApply(redrat) {
        editDeviceDialog.value = false;
        if (redrat.isAllowed) {
            await allowRedRat(redrat);
            await setName(redrat, editedRedRatName.value);
        } else {
            await blockRedRat(redrat);
        }
        await redratStore.loadRedRatData();
    }

    async function showEditNetworkDialog(redrat) {
        selectedRedRat.value = redrat;
        editNetworkDialog.value = true;
    }

    function showRemoveDeviceDialog(redrat) {
        selectedRedRat.value = redrat;
        removeDeviceDialog.value = true;
    }

    async function removeRedRat() {
        removeDeviceDialog.value = false;
        redratStore.deleteRedRatDevice(selectedRedRat.value);
        selectedRedRat.value = '';
        await redratStore.loadRedRatData();
    }

    async function rescan() {
        scanning.value = true;
        try {
            await redratStore.loadRedRatData(true);
        } catch (error) {
            console.log(error)
        }
    }

    function scanButtonName() {
        return scanning.value ? 'Scanning' : ' Rescan ';
    }

    async function updateNetwork(redrat, dhcp, ipAddress, netmask, gateway) {
        editNetworkDialog.value = false;
        clearTimeout(loadTimer.value);   // Stop reloading for a bit
        restartWaitDialog.value = true;
        // ToDo: Add timeout to remove dialog...
        await setNetworkInfo(redrat, dhcp, ipAddress, netmask, gateway);
        await loadRedRats();             // Start reloading again
        restartWaitDialog.value = false;
    }

    onBeforeUnmount(() => {
         clearTimeout(loadTimer.value);
    })

</script>
