import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getRedRats } from '../services/redrat-service';
import axios from 'axios';
import { handleAxiosError } from '../services/axios-service';

export const useRedRatStore = defineStore('redrat', () => {

    const redrats = ref([]);

    async function loadRedRatData(rescan = false) {
        try {
            const response = await axios.get(`/api/redrats?rescan=${rescan}`);
            redrats.value = response.data;
        } catch (error) {
            handleAxiosError(error);
        }
    }

    function deleteRedRatDevice(redrat) {
        axios.delete(`/api/RedRats/${redrat.name}`)
            .then(() => {
                redrats.value = redrats.value.filter(rr => rr.name !== redrat)
            })
             .catch(error => handleAxiosError(error))
     }


    return { redrats, loadRedRatData, deleteRedRatDevice }
})


