<template>
    <v-navigation-drawer menu-data="menuData"
                         expand-on-hover
                         rail>

        <v-list>
            <v-list-item prepend-icon="mdi-forwardburger"
                         link :to="menuData.page">
                <v-list-item-title class="text-h6 font-weight-medium">
                    {{ menuData.title }}
                </v-list-item-title>
            </v-list-item>
        </v-list>

        <v-divider></v-divider>

        <v-list>
            <v-list-item v-for="item in menuData.items"
                         :prepend-icon="item.icon"
                         :to="item.link">
                <v-list-item-title>
                    {{ item.title }}
                </v-list-item-title>
            </v-list-item>
        </v-list>

    </v-navigation-drawer>
</template>

<script setup>

    const props = defineProps(['menuData'])

</script>
