import { createWebHistory, createRouter } from 'vue-router';

import devices from './pages/Devices.vue';
import settings from './pages/Settings.vue';
import log from './pages/Log.vue';
import docs from './pages/Docs.vue';

const routes = [
    { path: '/', component: devices },
    { path: '/settings', component: settings },
    { path: '/log', component: log },
    { path: '/docs', component: docs },
    
]

const router = new createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
                top: 75,
            }
        }
    }
})

export default router;
