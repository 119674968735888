import axios from 'axios';
import {handleAxiosError} from './axios-service';

export async function getFirmware(redrat) {
    if (!redrat.isAllowed) return "";
    try {
        const response = await axios.get(`/api/redrats/${getRedRatId(redrat)}/firmwareversion`);
        return response.data;
    } catch (error) {
        handleAxiosError(error);

    }
}

export async function getSerialNumber(redrat) {
    if (!redrat.isAllowed) return "";
    try {
        const response = await axios.get(`/api/redrats/${getRedRatId(redrat)}/serialnumber`);
        return response.data;
    } catch (error) {
        handleAxiosError(error);
    }
}

export async function getNetworkInfo(redrat) {
    if (!redrat.isAllowed) return {};
    try {
        const response = await axios.get(`/api/redrats/${getRedRatId(redrat)}/network`);
        return {
            ...response.data,
            dhcp: response.data.assignment === 'Dynamic'
        };
    } catch (error) {
        handleAxiosError(error);
    }
}

export async function setNetworkInfo(redrat, dhcp, ipAddress, netmask, gateway) {
    try {
        const formData = new FormData();
        formData.append('dhcp', dhcp);
        formData.append('ipAddress', ipAddress);
        formData.append('netmask', netmask);
        formData.append('gateway', gateway);

        await axios.put(`/api/redrats/${getRedRatId(redrat)}/network`,
            formData,
            {headers: {"content-type": "application/x-www-form-urlencoded"}});
    } catch (error) {
        handleAxiosError(error);
    }
}

export async function allowRedRat(redrat) {
    try {
        await axios.put(`/api/redrats/${getRedRatId(redrat)}/allow`);
    } catch (error) {
        handleAxiosError(error);
    }
}

export async function blockRedRat(redrat) {
    try {
        await axios.put(`/api/redrats/${getRedRatId(redrat)}/block`);
    } catch (error) {
        handleAxiosError(error);
    }
}

export async function setName(redrat, newName) {
    try {
        await axios.put(`/api/redrats/${getRedRatId(redrat)}/name`, `newName=${newName}`);
    } catch (error) {
        handleAxiosError(error);
    }
}

export function getRedRatId(redrat) {
    // If an IP connected device, use the IP address rather than the name.
    // Reduces chances of issues with strange name chars.

    return redrat.connection === 'USB' ? redrat.name : redrat.connection;
}
