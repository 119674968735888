<template>
    <v-container fluid class="pa-4">
        <p class="text-h4 my-3">RedRatHub Settings</p>

        <p class="text-body-2 mb-3">
            RedRatHub's default behaviour can be changed using either a the configuration file <code>appsettings.json</code> or via command
            line arguments.
        </p>

        <p class="text-body-2 mb-3">
            Generally, the command line arguments take precendence over the configuration file. The table below describes all settings and
            their source, i.e. whether they come from the settings file, the command line or are just default behaviour.
        </p>
        <v-data-table :headers="settingsHeaders"
                      :items="settingsItems"
                      item-key="text"
                      :group-by="groupBy"
                      items-per-page="20"
                      hover
                      class="elevation-1 mb-8 text-body-2">
            <template #headers="{columns}" class="table-header ">
                <tr >
                    <template v-for="column in columns" :key="column.key">
                        <td v-if="column.title !== 'Group'" class="td-border " :width="column.width">
                            <span class="mr-2 ">{{ column.title }}</span>
                        </td>
                    </template>
                </tr>
            </template>
            <template #item="{ item }">
                <tr>
                    <td>{{ item.name }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.value }}</td>
                    <td>{{ item.source }}</td>
                    <td>{{ item.default }}</td>
                </tr>
            </template>
            <template #group-header="{ item, columns, toggleGroup, isGroupOpen }">
                <tr>
                    <td :colspan="columns.length" class="group-header">
                        <VBtn size="small"
                              variant="text"
                              :icon="isGroupOpen(item) ? '$expand' : '$next'"
                              @click="toggleGroup(item)"></VBtn>
                        {{ item.value }}
                    </td>
                </tr>
            </template>
            <template #bottom>
            </template>
        </v-data-table>

            <div class="text-h5 my-3">Log Settings</div>

            <div class="text-body-2 mb-3">
                Logging is configured in the file <code>NLog.config</code>. Any changes made will be picked up by RedRatHub while running.
            </div>

            <v-data-table :headers="logInfoHeaders"
                          :items="logConfigItems"
                          :hide-default-footer="true"
                          :disable-pagination="true"
                          class="elevation-1 vspace mb-5 text-body-2 ">
                <template #headers="{columns}" class="table-header">
                    <tr>
                        <template v-for="column in columns" :key="column.key">
                            <td class="td-border" >
                                <span class="mr-2 ">{{ column.title }}</span>
                            </td>
                        </template>
                    </tr>
                </template>
                <template #bottom>
                </template>

            </v-data-table>

            <div class="text-h5 my-3">IR Datasets</div>

            <div class="text-body-2 mb-3">
                IR datasets loaded by RedRat Hub:
            </div>

    <v-list class="text-body-2"
            density="compact"
            :items="datasets"
            item-title="name"
            >
        <template #prepend="{isActive, item}">
            <v-icon v-if="item.signal" >
                mdi-arrow-right-thick
            </v-icon>
            <div v-else-if="isActive">
                <v-icon color="rgba(0, 0, 0, 0.54)">
                    mdi-menu-down
                </v-icon>
                <v-icon color="rgba(0, 0, 0, 0.54)">
                    mdi-folder-open
                </v-icon>
            </div>
            <div v-else>
                <v-icon color="rgba(0, 0, 0, 0.54)">
                    mdi-menu-right
                </v-icon>
                <v-icon color="rgba(0, 0, 0, 0.54)">
                    mdi-folder
                </v-icon>
            </div>
        </template>
        <template #append="{item}">
            <span v-if="item.signal">
                <a :href="`/api/irdata/${item.url}`" target="_blank" title="Load JSON"> <v-icon size="18">mdi-json</v-icon></a>
            </span>
        </template>
    </v-list>
</v-container>
</template>

<script setup>
    import { ref, onMounted } from 'vue'
    import axios from 'axios';
    import { handleAxiosError } from '../services/axios-service'

    const settingsHeaders = [
        { title: 'Name', key: 'name', width: '12%'},
        { title: 'Description', key: 'description', width: '49%' },
        { title: 'Value', key: 'value', width: '13%' },
        { title: 'Source', key: 'source', width: '13%' },
        { title: 'Default', key: 'default', width: '13%' },
    ]

    const logInfoHeaders = [
        { title: 'Log Output', key: 'log' },
        { title: 'Current Levels', key: 'level' },
    ]

    const groupBy = [{ key: 'category' }]

    const settingsItems = ref([])
    const logConfigItems = ref([])
    const datasets = ref([])

    onMounted(() => {
        loadSettings();
        loadLogConfig();
        loadIrData();
    })

    async function loadSettings() {
        axios.get('/api/config/settings')
            .then(response => settingsItems.value = response.data)
            .catch(error => handleAxiosError(error));
    }

    async function loadLogConfig() {
        axios.get('/api/config/logsettings')
            .then(response => logConfigItems.value = response.data)
            .catch(error => handleAxiosError(error));
    }

    async function loadIrData() {
        axios.get('/api/irdata')
            .then(response => {
                let id = 0;
                datasets.value = response.data.map(({ name, signals }) => ({
                    id: id++,
                    name,
                    children: signals.map((s) => ({
                        id: id++,
                        name: s,
                        signal: true,
                        url: `${name}/${s}`,
                    })),
                }));
            })
            .catch(error => handleAxiosError(error));
    }
</script>
