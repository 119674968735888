import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';

import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Not all components have reached full vuetify release
import * as labsComponents from 'vuetify/labs/components'

import 'regenerator-runtime';

import router from './router';
import './css/appStyles.scss';

import Toolbar from './components/Toolbar.vue';
import Footer from './components/Footer.vue';
import AlertBox from "./components/AlertBox.vue";
import NetworkProps from './components/NetworkProps.vue';
import NavigationDrawer from './components/NavigationDrawer.vue';

const pinia = createPinia();

const vuetify = createVuetify({
    components: {
        ...components,
        ...labsComponents,
    },
    directives,
});

const app = createApp(App)
    .use(pinia)
    .use(vuetify)
    .use(router)
    .component('alertBox', AlertBox)
    .component('toolbar', Toolbar)
    .component('customFooter', Footer)
    .component('networkProps', NetworkProps)
    .component('NavigationDrawer', NavigationDrawer)
    .mount('#app');
