import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useLogStore = defineStore( 'log', () => {
    const logMessages = ref([]);

    function addLogMessage(logMessage) {
        logMessages.value.unshift(logMessage);
        logMessages.value.splice(100);
    }

    return { logMessages, addLogMessage };
});
